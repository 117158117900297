$blue: #0a6fc2;
$green: #2bb69e;
$red: #f67777;

$gray-1: #dedede;
$gray-2: #9b9b9b;
$gray-3: #505050;
$gray-4: #212121;
$gray-5: #1d1c1c;

$font-family-sans-serif:      "Lexend", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;