@import 'variables';

.home {
  @extend .pageContainer;
  main {
    background: $gray-4;

    /*> div {
      margin: 0 auto;
      max-width: 50rem;
    }*/
  }
}

.hero {
  width: 100%;
  height: calc(100vh - 5rem);
  background: url('https://assets.scrutiny.design/images/marvin-meyer-SYTO3xs06fU-unsplash.jpg') no-repeat center center;
  max-width: 100%;

  .heroOverlay {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .heroContent {
    background: rgba(255, 255, 255, 1);
    box-shadow: 1rem 1rem 1rem 0px rgba(0, 0, 0, 0.5);
    color: #000;
    text-align: center;
    border-radius: 2rem;
    padding: 5rem 4rem;

    h1 {
      font-size: 3rem;
      margin: 0;
    }

    p {
      font-size: 3rem;
      line-height: 4rem;
    }

    .heroButtons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      gap: 1rem;
    }
  }

  img {
    width: 40rem;
  }
}

.feature-bg-1 {
  background: linear-gradient(90deg, #24282d 50%, #212121 99%);
}

.feature-bg-2 {
  background: linear-gradient(270deg, #24282d 50%, #212121 99%);
}


.feature {
  padding: 2rem 2rem;
  border-radius: 0.5rem;

  h4 {
    color: white;
  }

  p {
    color: white;
    margin: 0;
  }

}

.testimonial {
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 0 auto;
}


.bg-rainbow {
  /* W3C */
  $duration: 25s;
  $colors: #131ae8,
  #ff28fb,
  #ff4545,
  #fff700,
  #1ced1c,
  #21d1ce,
  #131ae8,
  #ff28fb,
  #ff3232,
  #fff700,
  #1df51d,
  #28fcf8,
    // repeat first color to create illusion of infinite animation
  #131ae8,
  #131ae8,;

  // build a list of color stops
  $stops: ();
  $stopsSafari4: ();
  @for $i from 1 through length($colors) {
    $stops: append($stops, #{nth($colors, $i)} #{percentage($i / length($colors))}, comma);
    $stopsSafari4: append($stopsSafari4, color-stop(#{percentage($i / length($colors))}, nth($colors, $i)), comma);
  }
  background: linear-gradient(top, $stops);

  /* Firefox */
  background: -moz-linear-gradient(top, $stops);

  /* Chrome,Safari4+ */
  background: -webkit-gradient(linear, left top, left bottom, $stopsSafari4);

  /* Chrome10+,Safari5.1+ */
  background: -webkit-linear-gradient(top, $stops);

  background-size: 1000%;
  -moz-background-size: 1000%;
  -webkit-background-size: 1000%;

  /* W3C */
  animation-name: bg-rainbow;
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;

  /* Firefox: */
  -moz-animation-name: bg-rainbow;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: normal;
  -moz-animation-play-state: running;

  /* Chrome, Safari */
  -webkit-animation-name: bg-rainbow;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -webkit-animation-play-state: running;
}

/* W3C */
@keyframes bg-rainbow {
  0% {
    background-position: left top;
  }
  100% {
    background-position: left bottom;
  }
}

/* Firefox */
@-moz-keyframes bg-rainbow {
  0% {
    background-position: left top;
  }
  100% {
    background-position: left bottom;
  }
}

/* Chrome, Safari */
@-webkit-keyframes bg-rainbow {
  0% {
    background-position: left top;
  }
  100% {
    background-position: left bottom;
  }
}

.fg-rainbow {
  padding: 1px;
  position: relative;
}

.fg-rainbow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @extend .bg-rainbow;
  mix-blend-mode: screen;
  z-index: 1;
}

.rainbow{
  animation: rainbow 2.5s linear;
  animation-iteration-count: infinite;
}

@keyframes rainbow{
  100%,0%{
    color: rgb(255,0,0);
  }
  8%{
    color: rgb(255,127,0);
  }
  16%{
    color: rgb(255,255,0);
  }
  25%{
    color: rgb(127,255,0);
  }
  33%{
    color: rgb(0,255,0);
  }
  41%{
    color: rgb(0,255,127);
  }
  50%{
    color: rgb(0,255,255);
  }
  58%{
    color: rgb(0,127,255);
  }
  66%{
    color: rgb(0,0,255);
  }
  75%{
    color: rgb(127,0,255);
  }
  83%{
    color: rgb(255,0,255);
  }
  91%{
    color: rgb(255,0,127);
  }
}