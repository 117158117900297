@import 'variables';

.comment-wrapper {
  background-color: $gray-4;
  color: white;
  border: 1px solid #505050;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin-top: 0.5rem;
  transition: border 200ms ease-in;

  &:hover {
    border: 1px solid darken($gray-1, 15%);
    cursor: pointer;
  }

  .toggle-comment {
    i {
      color: $gray-3;
      transition: color 200ms ease-in;
      &:hover {
        cursor: pointer;
        color: white;
      }
    }
  }

  .comment-reply {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    input {
      flex-grow: 1;
      margin-right: 0.125rem;
      border-radius: 0.5rem;
      border: 1px solid $gray-1;
      padding: 0.25rem 0.25rem;

      &::placeholder {
        color: darken($gray-1, 15%);
      }

      &:focus {
        outline: none;
        border: 1px solid $gray-3;
      }
    }

    button {
      padding: 0.25rem 0.5rem;
    }
  }

}

.comment-header {

  .comment-sender {

    .sender {
      font-size: 0.90rem;
    }

    .date {
      color: darken($gray-1, 25%);
      font-size: 0.8rem;
    }
  }

  .toggle-comment {
    color: darken($gray-1, 25%);
    transition: color 200ms ease-in;

    &:hover {
      color: black;
      cursor: pointer;
    }
  }
}

.comment-text {
  margin-top: 0.5rem;
  font-size: 0.90rem;
}

.selected-comment {
  border: 1px solid $green !important;

  &:hover {
    border: 1px solid $green !important;
  }

}