@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');
@import "node_modules/bootstrap/scss/_functions.scss";
@import 'variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  background-color: $gray-4;
  height: 100%;
}

body {
  background-color: $gray-4 !important;
}

.pageContainer {
  height: 100vh;
  width: 100%;
  margin: 0;
}

#__next {
  height: 100%;
}

.mainWrapper {
  height: 100%;
  position: relative;
}

#content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  z-index: 0;
  overflow: hidden;
}

hr {
  color: $gray-1 !important;
}

/*Bar*/

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  background-color: $gray-4;
  max-width: 20rem;
  min-width: 15rem;
  border-radius: 0.5rem;
  border: 1px solid #505050;
  padding: 0.25rem 0.25rem;

  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 34%);

  z-index: 2;


  .active {
    color: $gray-1;
  }

  .active-commenting {
    color: $green;
  }

  i {
    color: $gray-3;
    margin: 0.25rem;
    border-radius: 0.5rem;
    transition: color 200ms ease-in;

    &:hover {
      cursor: pointer;
      color: darken($gray-3, 15%);
    }
  }

  .contentLeft {
    display: flex;

    padding: 0.25rem 0.25rem;
    border-radius: 0.5rem;

    justify-content: space-between;
    align-items: center;
  }

  .contentMiddle {
    font-size: 0.9rem;
  }

  .contentRight {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.5rem;
    height: 2rem;
    padding: 0.25rem;
  }
}

.green-button {
  padding: 0.5rem 1rem;
  background-color: $green;
  color: white !important;
  border-radius: 4px;
  border: none;
  transition: background-color 200ms ease-in, box-shadow 200ms ease-in;

  &:hover {
    cursor: pointer;
    background-color: darken($green, 5%);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.navbar {
  background-color: $gray-4 !important;
  border-bottom: 1px solid #505050;
  color: white;

  a, button {
    color: white !important;
    font-weight: bold !important;
  }
}

.icon-hover {
  cursor: pointer;
}

ul {
  list-style: none;
  margin: 0 !important;
}

.comments-panel-wrapper {

  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;

  background-color: $gray-4;

  .comments-panel {
    height: 100%;
    min-width: 25rem;
    padding: 1rem 1rem;
    overflow-y: auto;

    border-right: 1px solid #505050;

    .infoSection {
      color: white;
      display: flex;
      align-items: center;

      font-weight: 500;
      margin-bottom: 0.5rem;

      i {
        color: $gray-3;
        margin: 0.25rem;
        border-radius: 0.5rem;
        transition: color 200ms ease-in;

        &:hover {
          cursor: pointer;
          color: white;
        }

      }
    }

  }

  .comment-sidebar {

    // Fade into view and change opacity of content
    color: white;
    font-size: 0.85rem;
    border-right: 1px solid #505050;
    background-color: $gray-4;
    max-width: 25rem;
    min-width: 25rem;
    padding: 1rem 1rem;
    overflow-y: auto;

    .comment-sidebar-header {
      border-bottom: 1px solid #505050;

      i {
        color: $gray-3;
        transition: color 200ms ease-in;

        &:hover {
          cursor: pointer;
          color: white;
        }
      }

    }

    .link-highlight {
      padding: 0.25rem 0.5rem;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      background-color: $green;
      color: white;

      text-decoration: none;

      transition: color 200ms ease-in;

      &:hover {
        color: black;
      }
    }

    .tags {
      padding: 0.25rem 0.25rem;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      background-color: $gray-3;
      color: $gray-1;
    }

  }
}

.btn-sd-outline-primary {
  color: $green !important;
  font-size: 0.8rem !important;
  border: 1px solid $green !important;
  border-radius: 0.25rem !important;
  transition: background-color 200ms ease-in, color 200ms ease-in !important;

  &:hover {
    cursor: pointer !important;
    background-color: $green !important;
    color: white !important;
  }
}

.btn-status-button {
  background-color: white;
  border: 1px solid $gray-3 !important;
  font-size: 0.85rem !important;
  color: white !important;
  transition: border 200ms ease-in;

  &:hover {
    color: white !important;
    border: 1px solid $gray-3 !important;
  }

}


.statusBar {

  .status {
    color: white;
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
  }

  .progressBar {
    width: 100%;
    height: 0.5rem;
    background-color: $gray-1;
    border-radius: 0.5rem;
  }

  .progressBarComplete {
    height: 0.5rem;
    background-color: $green;
    border-radius: 0.5rem;
    transition: width 1s ease 0.3s;
  }
}

.latest-comment {
  border: 1px solid #505050;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.primary-link {
  text-decoration: none;
  color: $gray-2;
  transition: color 200ms ease-in;

  &:hover {
    color: $green;
  }
}

.scrollable {
  overflow-y: scroll;
  height: calc(100vh - 5rem);
  margin-right: 4px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-bottom: 5rem;
}

// scrollbar styles
::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $gray-3;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($gray-3, 20%);
}

.status-indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 180px;
  background-color: $gray-1;
}

.user-profile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 180px;
  background: linear-gradient(217deg, rgba(109, 210, 232, 0.8), rgba(121, 227, 169, 0.85) 70.71%);
}

.small-text {
  font-size: 0.85rem;
}

.pill {
  border: 1px solid $gray-1;
  padding: 5px;
  border-radius: 0.5rem;
  color: $gray-3;
}

.pill-selected {
  border-color: $green;
  color: $green;
}

.organization {

  background-color: $gray-4;
  border: 1px solid $gray-3;
  border-radius: 0.5rem;
  transition: border 200ms ease-in;

  &:hover {
    border: 1px solid $gray-2;
    cursor: pointer;
  }

  .title {
    border-bottom: 1px solid lighten($gray-4, 5%);
    padding-bottom: 0.5rem;

    .org-free {
      background: linear-gradient(217deg, rgba(158, 114, 252, 0.8), rgba(102, 141, 225, 0.85) 70.71%);
      background-size: 400% 400%;
      border-radius: 0.5rem;
      width: fit-content;
      padding: 0.25rem 0.75rem;
    }

    .org-premium {
      background: linear-gradient(217deg, rgba(248, 151, 246, 0.8), rgba(178, 40, 114, 0.85) 70.71%);
      background-size: 400% 400%;
      border-radius: 0.5rem;
      width: fit-content;
      padding: 0.25rem 0.75rem;
      animation: gradient 2s ease infinite;
    }
  }

  .owner-info {
    .user-profile {
      width: 1rem;
      height: 1rem;
    }
  }

}

.profile-color-1 {
  background: linear-gradient(217deg, rgba(109, 210, 232, 0.8), rgba(121, 227, 169, 0.85) 70.71%) !important;
}

.profile-color-2 {
  background: linear-gradient(217deg, rgba(55, 236, 84, 0.8), rgba(62, 119, 211, 0.85) 70.71%) !important;
}

.profile-color-3 {
  background: linear-gradient(217deg, rgba(205, 109, 232, 0.8), rgba(179, 155, 253, 0.85) 70.71%) !important;
}

.profile-color-4 {
  background: linear-gradient(217deg, rgba(229, 76, 76, 0.8), rgba(241, 176, 96, 0.85) 70.71%) !important;
}

.profile-color-5 {
  background: linear-gradient(217deg, rgba(93, 166, 24, 0.8), rgba(57, 119, 86, 0.85) 70.71%) !important;
}

.profile-color-6 {
  background: linear-gradient(217deg, rgba(86, 97, 238, 0.8), rgba(89, 37, 124, 0.85) 70.71%) !important;
}

.profile-color-7 {
  background: linear-gradient(217deg, rgba(248, 151, 246, 0.8), rgba(178, 40, 114, 0.85) 70.71%) !important;
}

.profile-color-8 {
  background: linear-gradient(217deg, rgba(238, 181, 138, 0.8), rgba(229, 148, 34, 0.85) 70.71%) !important;
}

.profile-color-9 {
  background: linear-gradient(217deg, rgba(109, 210, 232, 0.8), rgba(86, 149, 161, 0.85) 70.71%) !important;
}

.profile-color-10 {
  background: linear-gradient(217deg, rgba(109, 210, 232, 0.8), rgba(169, 101, 215, 0.85) 70.71%) !important;
}

.normal-link {
  color: $green;
}

.org-tier {
  border: 1px solid $gray-3;
  border-radius: 0.5rem;

  .free-title {
    background: linear-gradient(217deg, rgba(158, 114, 252, 0.8), rgba(102, 141, 225, 0.85) 70.71%);
    background-size: 400% 400%;
    border-radius: 0.5rem;
    width: fit-content;
    padding: 0.25rem 1.5rem;
  }

  .premium-title {
    background: linear-gradient(217deg, rgba(248, 151, 246, 0.8), rgba(178, 40, 114, 0.85) 70.71%);
    background-size: 400% 400%;
    border-radius: 0.5rem;
    width: fit-content;
    padding: 0.25rem 1.5rem;
    animation: gradient 2s ease infinite;
  }

  .enterprise-title {
    background: linear-gradient(217deg, rgba(109, 66, 239, 0.8), rgba(218, 108, 255, 0.85) 70.71%);
    background-size: 400% 400%;
    border-radius: 0.5rem;
    width: fit-content;
    padding: 0.25rem 1.5rem;
  }

  a {
    text-decoration: none;
    color: $green;
  }

  ul {
    list-style: circle;
    font-size: 0.85rem;
    font-weight: normal;
    color: $gray-1;
  }

}

.price-tier {
  height: 40rem;

  .pricing-items {
    display: flex;
    flex-direction: column;
    list-style: none !important;

    li {
      font-size: 1rem;
      margin-top: 0.25rem;
    }
  }
}



@import 'node_modules/bootstrap/scss/bootstrap.scss';

.table {
  --bs-table-bg: $gray-1;
  --bs-table-border-color: #212121;

  thead {
    font-size: 0.85rem;
    --bs-table-color: white;
  }

  tbody {
    font-size: 0.85rem;
    --bs-table-color: #9b9b9b;
  }
}

.btn-user-selection {
  border: none;
  background-color: $gray-4;
  padding: 0;
  color: white !important;

  &:hover {
    color: $gray-2 !important;
  }

  &:active {
    background-color: $gray-4 !important;
    color: $gray-3 !important;
    border: none !important;
  }

  &:focus-visible {
    background-color: $gray-4 !important;
    color: $gray-3 !important;
    border: none !important;
  }
}

.toast {
  background-color: $gray-4;
  border: 1px solid #313131;
  filter: drop-shadow(2px 0px 5px #3b3b3b);
}

.toast-container {
  position: fixed;
}

.toast-header {
  background-color: $gray-4;
  color: $gray-1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.toast-body {
  background-color: $gray-4;
  color: $gray-1;
  border-radius: 0.5rem;
}

.btn-primary {
  background-color: $green;
  font-size: 0.85rem;
  border-radius: 0.5rem;
  border: 1px solid $green;

  &:hover {
    background-color: darken($green, 5%) !important;
    border: 1px solid darken($green, 5%) !important;
  }

  &:focus-visible {
    background-color: darken($green, 5%) !important;
    border: 1px solid darken($green, 5%) !important;
  }

  &:active {
    background-color: darken($green, 5%) !important;
    border: 1px solid darken($green, 5%) !important;
  }

  &:focus-visible {
    background-color: darken($green, 5%) !important;
    border: 1px solid darken($green, 5%) !important;
  }

}

.btn-primary:disabled {
  background-color: $green;
  border: 1px solid $green;
}

.btn-outline-danger {
  color: $red !important;
  font-size: 0.85rem !important;
  border: 1px solid $red !important;
  border-radius: 0.5rem !important;
  transition: background-color 200ms ease-in, color 200ms ease-in !important;

  &:hover {
    cursor: pointer !important;
    border: 1px solid darken($red, 5%) !important;
    color: darken($red, 5%) !important;
  }

  &:focus-visible {
    background-color: lighten($red, 5%) !important;
  }
}

.btn-danger {
  background-color: $red;
  font-size: 0.85rem;
  border-radius: 0.5rem;
  border: 1px solid $red;
  color: white;

  &:hover {
    background-color: darken($red, 5%) !important;
    border: 1px solid darken($red, 5%) !important;
  }

  &:focus-visible {
    background-color: darken($red, 5%) !important;
    border: 1px solid darken($red, 5%) !important;
  }

  &:active {
    background-color: darken($red, 5%) !important;
    border: 1px solid darken($red, 5%) !important;
  }

  &:focus-visible {
    background-color: darken($red, 5%) !important;
    border: 1px solid darken($red, 5%) !important;
  }

}

.navbar {

  a {
    font-weight: normal !important;
  }

  .dropdown-toggle:after {
    content: none !important;
  }

}

.modal-content {
  background-color: $gray-4;
  color: white;
}

.modal-footer {
  border-top: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-title {
  font-size: 1rem;
}

input {
  background-color: $gray-4 !important;
  border-color: #292929 !important;
  color: white !important;

  &::placeholder {
    color: $gray-3 !important;
  }
}

textarea {
  background-color: $gray-4 !important;
  border-color: #292929 !important;
  color: white !important;

  &::placeholder {
    color: $gray-3 !important;
  }
}

.dropdown-menu {
  background-color: $gray-5;

  .dropdown-item {
    color: white;
    &:hover {
      background-color: lighten($gray-4, 5%);
    }
  }
}

.dropdown-toggle {
  color: white;
}

.no-comments {
  color: $gray-2;
  font-size: 0.85rem;
  border: 1px solid #505050;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
}

.error-message {
  animation: message-fade-in 200ms ease-in;
  background-color: $red;
  padding: 0.5rem 0.25rem;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  filter: drop-shadow(1px 0px 3px $red);
}

.success-message {
  animation: message-fade-in 200ms ease-in;
  background-color: $blue;
  padding: 0.5rem 0.25rem;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  filter: drop-shadow(1px 0px 3px $blue);
}

.fade-in-300 {
  animation: message-fade-in 300ms ease-in;
}

.h-100-md {
  height: 100% !important;
}

@include media-breakpoint-down(md) {

  .remove-left-border-md {
    border-left: none !important;
  }

  .mt-2-md {
    margin-top: 0.5rem !important;
  }

  .mt-4-md {
    margin-top: 1.5rem !important;
  }

  .h-100-md {
    height: 0 !important;
  }

  .d-none-md {
    display: none !important;
  }

}

@include media-breakpoint-down(lg) {
  .d-none-md {
    display: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .ms-2-lg-up {
    margin-left: 0.5rem !important;
  }
}
.supabase-auth-ui_ui-button {
  border: 1px solid #505050 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem 0.25rem !important;
}

.supabase-auth-ui_ui-input {
  border-radius: 0.5rem !important;
  padding: 0.25rem 0.25rem !important;
}

.supabase-auth-ui_ui-anchor {
  color: $green !important;
}

.nav-link {

  &:hover {
    color: $green !important;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1.0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.live-icon {
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: $green;
  border: 1px solid rgba(black, 0.1);
  border-radius: 50%;
  z-index: 1;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba($green, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: live 2s ease-in-out infinite;
    z-index: -1;
  }
}

.primary-color-gradient {
  background: linear-gradient(90.58deg, #2bb69e 13.97%, #0a6fc2 84.16%);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.not-set-color-gradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.secondary-color-gradient {
  background: linear-gradient(90.58deg, #955acc 13.97%, #e64bf1 84.16%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba($green, 0);
  }
}

@keyframes message-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.font-85 {
  font-size: 0.85rem !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.auth-modal-link {
  color: #9b9b9b;
  text-align: center;
  font-size: 0.9rem;
  transition: color 200ms ease-in;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: lighten($gray-2, 10%);
  }
}

.navbar-auth.navbar-toggler {
  border-radius: 180px;

  .navbar-toggler-icon {
    display: none;
    background-image: none;
  }
}

.navbar-toggler {
  border: none;
}


.bar-bottom {
  background-color: blue;
}

// Import other SCSS files
@import '_Home.scss';
@import '_Comment.scss';